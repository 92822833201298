// COMPONENTS
import React from 'react'
// CUSTOM TEMPLATES
import LandingPage from 'templates/LandingPage'
// CONTENT
import content from 'content/lp/bearblogr.yml'

// bearblogr - Convert Bear into a blogging CMS
const bearblogrLP = () => {
  return <LandingPage content={content} />
}
export default bearblogrLP
